import React from "react"
import { graphql } from "gatsby"
import Apps from "../../components/apps"
import Section from "../../components/layouts/section"
import Grid from "../../components/layouts/grid"
import Column from "../../components/layouts/column"
import Card from "../../components/elements/card"
import Blank from "../../components/elements/blank"

export default ({ data, location }) => {
  const allShelter = data.wpgraphql.allShelter.edges
  return (
    <Apps>
      <Section padding="md">
        <Grid media="mobile" min="240px" max="300px" gap="24px">
          {allShelter.length !== 0 ? (
            allShelter.map((edge, i) => (
              <Column key={i}>
                <Card
                  cover={edge.node.featuredImage}
                  url={edge.node.uri}
                  title={edge.node.title}
                />
              </Column>
            ))
          ) : (
            <Blank />
          )}
        </Grid>
      </Section>
    </Apps>
  )
}

export const query = graphql`
  query PageShelter {
    wpgraphql {
      allShelter(where: { orderby: { field: DATE, order: DESC } }) {
        edges {
          node {
            title
            date
            uri
            featuredImage {
              altText
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
